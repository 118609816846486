.gradient-custom {
  /* fallback for old browsers */
  background: #6a11cb;
  
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));
  
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))
  }
.card bg-dark {
  border-radius: 1rem;
}
.MuiBox-root {
  width: 100%;
}
.error {
  color: red;
  margin-bottom: 5px;
}
.inner-wrapper {
  margin-top: 80px;
  margin-left: 10px;
}
.page-title {
  color: #1976d2;
  font-size: 30px;
}
.MuiDrawer-root.MuiDrawer-docked.css-cvumne-MuiDrawer-docked {
  height: 100vh;
}
@media (min-width: 576px){
.modal-dialog {
    margin: 6.75rem auto;
}
}
span.card-title {
  margin-bottom: 0.5rem;
  font-size: 22px;
  color: #1976d2;
  font-weight: 700;
}
#star-rating{
  font-size: 25px;
  font-weight: bolder;
  color: #1976d2;
  letter-spacing: 3px;
}
.pagination-wrapper {
  display: table;
  margin: 0 auto;
}
.buy_now_btn {
  background: #d3b4ef;
  border-color: #d3b4ef;
}
.shop_section .slider-wrapper {
  height: auto !important;
}
.shop_section .carousel .slide img {
  width: auto;
}
.fa-heart.position-relative {
  font-size: 18px;
}
.rounded-pill.bg-danger {
  font-size: 8px;
}
.cursor-pointer {
  cursor: pointer;
}
.strikked-text {
  text-decoration: line-through;
  padding-right: 10px;
}
.product-price {
  font-size: 16px;
  background: #1ca16347!important;
  color: #000;
}
.product-original-price {
  color: #00000073;
}
.rating-name {
  color: #4471c3;
  font-weight: bolder;
  font-size: 20px;
}
.float-end.product-detail-rating i {
  color: #ffd808;
  font-size: 25px;
}
.image-parent {
  /* max-width: 150px; */
  float: left;
}
.list-group {
  padding: 30px 0;
}
span.list-text {
  margin-left: 50px;
}
li.list-group-item {
  padding: 15px;
}
img.img-small {
  width: auto;
  height: 150px;
  padding-right: 30px;
}
.layout_padding {
  padding-top: 60px !important;
  padding-bottom: 90px;
}
.add_to_wishlist_btn {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background: transparent;
  font-size: 20px;
  padding: 10px 10px 0 0;
}
.add_to_wishlist_btn:hover, .add_to_wishlist_btn:focus{
  background: transparent;
  border: 0;
  outline: 0;
}
.add_to_wishlist_btn:active:focus {
  box-shadow: none;
}
.add-to-cart-header {
  position: relative;
}
.kart-grid-image {
  height: auto;
  width: 250px;
}
@media only screen and (max-width: 990px) {
  .kart-grid-image {
    height: auto;
    width: 100%;
  }
}
.no-kart-products span {
  width: 100%;
}
ul.rating li {
  list-style: none;
  float: left;
}
ul.rating span{
  display: flex;
}
ul.rating{
  display: table;
  margin: 7px auto;
}