@media (max-width: 1120px) {
    .saving_section .detail-box {
        padding: 0;
    }
}

@media (max-width: 992px) {
    .user_option {
        justify-content: center;
        margin-left: 0;
        margin-top: 10px;

    }

    .custom_nav-container {
        flex-direction: row;
    }

    .custom_nav-container .navbar-nav {
        align-items: center;
    }

    #navbarSupportedContent .navbar-nav .nav-link {
        margin: 5px 0;
    }

    .slider_section .detail-box {
        padding-left: 0;
    }

    .slider_section .detail-box h1 {
        font-size: 3rem;
    }

    .slider_section .img-box {
        padding: 0;
    }

    .saving_section .row {
        flex-direction: column-reverse;
    }

    .saving_section .detail-box {

        margin-bottom: 30px;
    }

    .client_section .box {
        margin: 15px 25px;
    }

    .client_section .carousel_btn-box {
        display: flex;
        justify-content: center;
        margin-top: 45px;
    }

    .client_section .carousel-control-prev,
    .client_section .carousel-control-next {
        position: unset;
        margin: 0 2.5px;
        width: 45px;
        height: 45px;
    }
}

@media (max-width: 768px) {
    .hero_area {
        padding: 0 25px;
    }

    .slider_section .img-box {
        margin-bottom: 45px;
    }

    .saving_section,
    .gift_section {
        padding-left: 25px;
        padding-right: 25px;
    }

    .gift_section .box {
        padding: 45px 0;
    }

    .gift_section .img-box {
        padding: 0 15px;
        margin-bottom: 45px;
    }

    .info_section .row>div {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .info_section {
        margin: 0 25px 25px;
    }


}

@media (max-width: 576px) {
    .contact_section {
        padding-left: 25px;
        padding-right: 25px;
    }
}

@media (max-width: 480px) {

    .saving_section .detail-box,
    .gift_section .detail-box {
        padding: 0 5px;
    }
}

@media (max-width: 420px) {}

@media (max-width: 376px) {}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}